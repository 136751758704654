import { FC } from 'react'
import Marquee from 'react-fast-marquee'
import cn from 'classnames'
interface props {
  primary: {
    scrolling_texts?: string | undefined
    texts_colour?: string | undefined
    background_colour?: string | undefined
  }
  applyWrapper?: boolean
}

const ScrollingTexts: FC<props> = ({ primary }) => {
  const { scrolling_texts, texts_colour, background_colour } = primary || {}
  const scrollingTextsArray = scrolling_texts?.split(',')

  return (
    <div className={cn('w-full scrollingTexts')} style={{ background: background_colour }}>
      <Marquee loop={0} speed={60}>
        <div className="flex gap-36 w-full justify-between py-24 scrollingContainer">
          {scrollingTextsArray?.map((item, index) => {
            return (
              <div className="title pl-36" style={{ color: texts_colour }} key={index}>
                {item}
              </div>
            )
          })}
        </div>
      </Marquee>

      <style jsx global>
        {`
          .scrollingTexts .marquee-container .overlay {
            display: none;
          }
        `}
      </style>
      <style jsx>
        {`
          .scrollingTexts {
            padding-left: 0;
            padding-right: 0;
          }
          .scrollingTexts .scrollingContainer {
            padding-left: 36px;
          }

          @media (min-width: 2200px) {
            .scrollingTexts .scrollingContainer {
              padding-left: 120px !important;
            }
          }
        `}
      </style>
    </div>
  )
}

export default ScrollingTexts
